import React from "react"
import Reveal from "react-reveal/Reveal"
import styles from "../styles/components/email-submit.module.scss"
import ArrowRight from "../images/arrow-right.svg"

class EmailSubmit extends React.Component {
  constructor(props) {
    super(props)

    this.state = { FNAME: "", LNAME: "", EMAIL: "", isFacebookApp: false }
  }

  componentDidMount() {
    this.setState({ isFacebookApp: this.isFacebookApp() })
  }

  handleChange = event => {
    const name = event.target.name
    this.setState({ [name]: event.target.value })
  }

  isFacebookApp() {
    var ua = navigator.userAgent || navigator.vendor || window.opera
    return (
      ua.indexOf("FBAN") > -1 ||
      ua.indexOf("FBAV") > -1 ||
      ua.indexOf("Instagram") > -1
    )
  }

  render() {
    return (
      <Reveal effect="fadeIn">
        <form
          className={styles.container}
          action="https://flybridge.us20.list-manage.com/subscribe/post?u=6bc74cb469be9ae9f781a377d&amp;id=005c538805"
          method="post"
          target={this.state.isFacebookApp ? "_self" : "_blank"}
        >
          <input
            type="text"
            name="FNAME" //name prop must match Mailchimp merge tag
            aria-label="First Name"
            value={this.state.firstName}
            onChange={this.handleChange}
            required
            placeholder="First Name"
          />
          <input
            type="text"
            name="LNAME"
            aria-label="Last Name"
            value={this.state.lastName}
            onChange={this.handleChange}
            required
            placeholder="Last Name"
          />
          <input
            type="email"
            name="EMAIL"
            aria-label="Email"
            value={this.state.email}
            onChange={this.handleChange}
            required
            placeholder="Email"
          />
          {/*real people should not fill this in and expect good things - do not remove this or risk form bot signups*/}
          <div
            style={{ position: "absolute", left: "-5000px" }}
            aria-hidden="true"
          >
            <input
              type="text"
              name="b_6bc74cb469be9ae9f781a377d_005c538805"
              tabIndex="-1"
              value=""
              onChange={this.handleChange}
            />
          </div>
          <button type="submit">
            <div className={styles.button}>
              <span>Submit</span>
              <ArrowRight />
            </div>
          </button>
        </form>
      </Reveal>
    )
  }
}

export default EmailSubmit
