import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Reveal, Fade } from "react-reveal"

import styles from "../styles/components/contact-section.module.scss"

import EmailSubmit from "./emailSubmit"
import ContactFlag1 from "../images/contact-flag-1.svg"
import ContactFlag2 from "../images/contact-flag-2.svg"
import ContactFlag3 from "../images/contact-flag-3.svg"

const ContactSection = ({ data }) => {
  const form = data.allContentfulEmailForm.edges[0].node
  return (
    <div style={{ position: "relative", overflow: "hidden" }}>
      <Fade right>
        <div className={styles.flag1}>
          <ContactFlag1 />
        </div>
      </Fade>
      <Fade right>
        <div className={styles.flag2}>
          <ContactFlag2 />
        </div>
      </Fade>
      <Fade right>
        <div className={styles.flag3}>
          <ContactFlag3 />
        </div>
      </Fade>
      <div className={styles.container}>
        <Reveal effect="fadeIn">
          <h2 className={styles.sectionTitle}>{form.header}</h2>
        </Reveal>
        {form.bodyText && (
          <Reveal effect="fadeIn">
            <p className={styles.paragraph}>{form.bodyText}</p>
          </Reveal>
        )}
        <EmailSubmit />
      </div>
    </div>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulEmailForm {
          edges {
            node {
              header
              bodyText
            }
          }
        }
      }
    `}
    render={data => <ContactSection data={data} />}
  />
)
