import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import classNames from "classnames/bind"
import styles from "../styles/components/tile.module.scss"

import QuoteMarks from "../images/quote-marks.svg"
//import FlybridgeIcon from "../images/flybridge-icon.svg"
//import FlybridgeIconWhite from "../images/flybridge-icon-white.svg"

const cx = classNames.bind(styles)

const QuoteTile = props => {
  return (
    <div
      className={cx("tile", "quote", {
        fade: props.fade,
        active: props.active,
      })}
      onMouseEnter={() =>
        props.handleMouseEnter && props.handleMouseEnter(props.index)
      }
      onMouseLeave={() => props.handleMouseLeave && props.handleMouseLeave(-1)}
    >
      <div className={styles.tileInner}>
        <QuoteMarks />
        <div className={styles.description}>{props.quote}</div>
        <div className={styles.name}>{props.name}</div>
        <div>
          <span>{props.position}</span>
          <span>{props.company}</span>
        </div>
      </div>
    </div>
  )
}

const StatTile = props => {
  return (
    <div
      className={cx("tile", "stat", { fade: props.fade, active: props.active })}
      onMouseEnter={() =>
        props.link &&
        props.handleMouseEnter &&
        props.handleMouseEnter(props.index)
      }
      onMouseLeave={() =>
        props.link && props.handleMouseLeave && props.handleMouseLeave(-1)
      }
    >
      {props.link && (
        <a
          href={props.link ? props.link : ""}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.tileLink}
        >
          <div className={styles.tileInner}>
            <div className={styles.number}>{props.number}</div>
            <div className={styles.description}>{props.description}</div>
          </div>
        </a>
      )}
      {!props.link && (
        <div className={styles.tileInner}>
          <div className={styles.number}>{props.number}</div>
          <div className={styles.description}>{props.description}</div>
        </div>
      )}
    </div>
  )
}

const CompanyTile = props => {
  let backgroundColor = props.flagColor
    ? props.flagColor.charAt(0).toLowerCase() +
      props.flagColor
        .slice(1)
        .replace(" ", "")
        .replace("-", "")
    : ""
  return (
    <Link to={`/${props.slug}`} className={styles.tileLink}>
      <div
        className={cx("tile", "company", {
          fade: props.fade,
          active: props.active,
          fullGrid: props.fullGrid,
        })}
        onMouseEnter={() =>
          props.handleMouseEnter && props.handleMouseEnter(props.index)
        }
        onMouseLeave={() =>
          props.handleMouseLeave && props.handleMouseLeave(-1)
        }
      >
        <div className={cx("tileInner", "rotate")}>
          {/* <div className={styles.tileBack}>
            <div className={styles.name}>{props.name}</div>
            {props.shortDescription && (
              <div className={styles.shortDescription}>
                {props.shortDescription}
              </div>
            )}
          </div> */}
          <div className={styles.tileFront}>
            {props.showFlagsOnTitle && props.flagText && (
              <div className={cx("tag", backgroundColor)}>{props.flagText}</div>
            )}
            {props.logo && (
              <Img
                fluid={props.logo.fluid}
                alt={props.name}
                className={styles.image}
              />
            )}
            <div className={cx("overlay", "darkNavy")} />
          </div>
        </div>
      </div>
    </Link>
  )
}

const PersonTile = props => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(
        relativePath: { eq: "images/placeholder-thumbnail.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  //console.log(props)
  let backgroundColor = props.flagColor
    ? props.flagColor.charAt(0).toLowerCase() +
      props.flagColor
        .slice(1)
        .replace(" ", "")
        .replace("-", "")
    : ""
  //console.log(backgroundColor)
  return (
    <Link to={`/${props.slug}`} className={styles.tileLink}>
      <div
        className={cx("tile", "person", {
          fade: props.fade,
          active: props.active,
          flybridge: props.affiliation === "Flybridge",
          fullGrid: props.fullGrid,
        })}
        onMouseEnter={() =>
          props.handleMouseEnter && props.handleMouseEnter(props.index)
        }
        onMouseLeave={() =>
          props.handleMouseLeave && props.handleMouseLeave(-1)
        }
      >
        <div className={cx("tileInner", "rotate")}>
          <div className={styles.tileBack}>
            {props.quote && (
              <div className={styles.quote}>
                <QuoteMarks />
                <div className={styles.description}>{props.quote}</div>
                <div className={styles.name}>{props.name}</div>
                <div>
                  <span>{props.position}</span>
                  <span>{props.companyName}</span>
                </div>
              </div>
            )}
            {!props.quote && (
              <>
                {/* {props.affiliation === "Flybridge" && <FlybridgeIconWhite />} */}
                <div className={styles.name}>{props.name}</div>
                <div>
                  <span>{props.position}</span>
                  <span>{props.companyName}</span>
                </div>
              </>
            )}
          </div>
          <div className={styles.tileFront}>
            {/* {props.affiliation === "Flybridge" && <FlybridgeIconWhite />} */}
            {props.showFlagsOnTitle && props.flagText && (
              <div className={cx("tag", backgroundColor)}>{props.flagText}</div>
            )}

            <Img
              fluid={
                props.thumbnailImage
                  ? props.thumbnailImage.fluid
                  : data.placeholderImage.childImageSharp.fluid
              }
              alt={props.name}
              className={styles.image}
            />
            <div className={cx("overlay", "darkNavy")}>
              <div className={styles.name}>{props.name}</div>
              <div className={styles.positionCompany}>
                <span>{props.position}</span>
                <span>{props.companyName}</span>
              </div>
            </div>
          </div>
          {/* <div className={cx("overlay", backgroundColor)} /> */}
        </div>
      </div>
    </Link>
  )
}

const Tile = props => {
  switch (props.__typename) {
    case "ContentfulPerson":
      return <PersonTile {...props} />
    case "ContentfulCompany":
      return <CompanyTile {...props} />
    case "ContentfulQuoteTile":
      return <QuoteTile {...props} />
    case "ContentfulStatTile":
      return <StatTile {...props} />
    default:
      console.log("Invalid Tile Type")
      return null
  }
}

export default Tile
